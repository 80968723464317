import useCountdown from './../utils/hooks/useCountdown';

export default function Timer({targetDate}) {
    const [weeks, days, hours, minutes] = useCountdown(targetDate);
    return (
        <ShowCounter
            weeks={weeks}
            days={days}
            hours={hours}
            minutes={minutes}
        />
    );
}

const ShowCounter = ({weeks, days, hours, minutes}) => {
    return (
        <div className="show-counter">
            <DateTimeDisplay value={weeks} type={'w'} isDanger={false} />
            :
            <DateTimeDisplay value={days} type={'d'} isDanger={false} />
            :
            <DateTimeDisplay value={hours} type={'h'} isDanger={false} />
            :
            <DateTimeDisplay value={minutes} type={'m'} isDanger={false} />
        </div>
    );
};

const DateTimeDisplay = ({value, type, isDanger}) => {
    let newValue = String(value);

    return (
        <div className={isDanger ? 'countdown danger' : 'countdown'}>
            <div className="countdown__numbers">
                {newValue}{type}
            </div>
        </div>
    );
};