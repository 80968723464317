import Button from "../buttons/button";
import React from 'react'
import ReactPlayer from 'react-player'


export default function InfoSection() {
    return (
        <div className="info-section">
            <div className="info-section__left">
                <h2>The history of the NFT-Monopoly Game</h2>
                <div className="info-section__text">Monopoly is a multi-player economics-themed board game. In the game, players roll two dice to move around the game board, buying and trading properties, and developing them with houses and hotels. Players collect rent from their opponents, with the goal being to drive them into bankruptcy. Money can also be gained or lost through Chance and Community Chest cards, and tax squares. Players receive a stipend every time they pass "Go", and can end up in jail, from which they cannot move until they have met one of three conditions. The game has numerous house rules, and hundreds of different editions exist, as well as many spin-offs and related media. Monopoly has become a part of international popular culture, having been licensed locally in more than 103 countries and printed in more than 37 languages.</div>
                <div className="info-section__btns">
                    <Button addClass="button-small button-small--grey">
                        PDF presentation ENG
                    </Button>
                    <Button addClass="button-small button-small--grey">
                        PDF presentation CN
                    </Button>
                </div>
            </div>
            <div className="info-section__right">
                <div className="info-section__player">
                    <ReactPlayer
                        className='react-player'
                        url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                        width='100%'
                        height='100%'
                    />
                </div>
            </div>
        </div>
    )
}