import {useWeb3React} from "@web3-react/core";
import {InjectedConnector} from "@web3-react/injected-connector";
import {useCallback, useState, useEffect, useMemo, useContext} from 'react';
import React from "react";
export const MetaMaskContext = React.createContext(null);

export const MetaMaskProvider = ({children}) => {
    const {activate, account, library, connector, active, deactivate} = useWeb3React();
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const connect = async () => {
        setIsLoading(true);
        try {
            await activate(injected);
        } catch (err) {
            console.log(err)
        }
        finally {
            setIsLoading(false);
        }
    }

    const disconnect = async () => {
        try {
            await deactivate();
        } catch (err) {
            console.log(err)
        }
    }

    const handleIsActive = useCallback(() => {
        console.log('App is connected to metamask', active);
        setIsActive(active);
    }, [active])

    useEffect(() => {
        handleIsActive()
    }, [handleIsActive])

    useEffect(() => {
        connect()
    }, [])

    const values = useMemo(() => ({
        isActive,
        account,
        isLoading,
        connect,
        disconnect
    }), [isActive, isLoading])

    return (<MetaMaskContext.Provider value={values}>{children}</MetaMaskContext.Provider>)
}

export default function useMetaMask() {
    const context = useContext(MetaMaskContext);
    return context
}

const injected = new InjectedConnector({supportedChainIds: [1, 42, 1337]})

