export default function Button({
    children,
    addClass = "",
    onClick = () => false,
}) {
    return (
        <div className={`button ${addClass}`} onClick={onClick}>
            {children}
        </div>
    )
}