import {useNavigate} from "react-router-dom";
import {useEffect} from 'react';

export default function useRedirect(condition, target) {

    const navigate = useNavigate();

    useEffect(() => {
        if (condition) {
            navigate(target);
        }
    }, [condition])



}