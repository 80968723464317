export default function InstructionSection({title, image, mobImage, text}) {
    return (
        <div className="instruction">
            <h2 className="instruction__title">{title}</h2>
            <div className="instruction__text">{text}</div>
            <div className="instruction__image">
                <img src={image} />
            </div>
            <div className="instruction__image-mob">
                <img src={mobImage} />
            </div>
        </div>
    )
}