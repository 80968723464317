import BannerText from "../components/bannerText";
import Button from "../components/buttons/button";
import {Swiper, SwiperSlide} from 'swiper/react';
import PropertyCard from "../components/properyCard";
import {propertyTypes} from './../lib/propertyTypes';
import InfoSection from './../components/info-section/infoSection';
import ActionsSection from "../components/actionsSection/actionsSection";
import AnswerSection from './../components/answers-section/answersSection';
import useRedirect from './../utils/hooks/useRedirect';
import useMetaMask from './../utils/hooks/useMetaMask';

import bg1 from '../assets/images/main-bg/main_bg_sq_1.png';
import bg2 from '../assets/images/main-bg/main_bg_sq_2.png';
import bg3 from '../assets/images/main-bg/main_bg_dec_1.png';
import bg4 from '../assets/images/main-bg/main_bg_dec_2.png';
import bg5 from '../assets/images/main-bg/main_bg_dec_3.png';
import bg6 from '../assets/images/main-bg/main_bg_sq_3.png';
import bg7 from '../assets/images/main-bg/main_bg_sq_4.png';
import bg8 from '../assets/images/main-bg/main_bg_dec_4.png';

import TwitterIcon from '../assets/svg/twitter';
import FacebookIcon from '../assets/svg/facebook';
import YoutubeIcon from '../assets/svg/youtube';
import TelegramIcon from '../assets/svg/telegram';
import Countdown from 'react-countdown';

export default function Main() {

    const {isActive} = useMetaMask();
    useRedirect(isActive, "account");

    return (
        <div className="main" style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>



            <section className="main__banner main-banner" style={{paddingTop: 0}}>
                <img src={bg3} />
                

                <div className="main-banner__text" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="header__logo"></div>
                    <BannerText />
                    <img src={bg4} />

                    <div className="banner-text">
                        <h1 style={{fontSize: '29px', marginTop: '28px'}}>Official launch on June 2022</h1>
                    </div>


                    <div className="banner-text">
                        <a href="https://forms.gle/WpaqbLiWFpVikfVY6"><h1 style={{fontSize: '20px', marginTop: '28px'}}>⚡⚡⚡ Apply now ⚡⚡⚡</h1></a>
                    </div>
                    

                    <div className="footer__links">
                        <a href="https://www.youtube.com/channel/UChc8WqstYnWOrzXZ2cbUvoA"><YoutubeIcon /></a>
                        <a href="https://t.me/smartmonopoly"><TelegramIcon/></a>
                    </div>
                </div>
                <img src={bg6} />
            </section>


        </div>
    )
}