import ChevronIcon from "../../assets/svg/chevron"
import {useState} from 'react';

export default function Action({image, action, comment, amount}) {

    const [open, setOpen] = useState(false)

    const renderAmount = () => {
        if (amount > 0) {
            return (
                <div className="action__amount">
                    <span>+</span> <span>{amount}</span> BNB
                </div>
            )
        }
        return (
            <div className="action__amount red">
                <span>-</span> <span>{-amount}</span> BNB
            </div>
        )
    }

    return (
        <div className={`action ${open ? "open" : ""}`} onClick={() => setOpen(prev => !prev)}>
            <div className="action__col col1">
                <div className="action__label">Action</div>
                <div className="action__image">{image}</div>

                {action}
            </div>
            <div className="action__col col2">
                <div className="action__label">Comment</div>
                {comment}
            </div>
            <div className="action__col col3">
                <div className="action__label">Amount</div>
                {renderAmount()}
            </div>
            <div className="action__chevron">
                <ChevronIcon />
            </div>

        </div>
    )
}