import Button from "./buttons/button"
import LockIcon from './../assets/svg/lock';
import Timer from './timer';

export default function PropertyCard({
    property = "Hotel",
    image,
    type = "upgrade",
    statuses = [],
    price,
    openDate = false,
    additionalClass = "",
    activeProfit,
    passiveProfit
}) {

    const renderButtons = () => {
        switch (type) {
            case "buy": return <Button addClass="button-small">Buy object</Button>
            case "upgrade": return <Button addClass="button-small">Upgrade</Button>
            case "timer": return <Button addClass="button-small"><LockIcon /></Button>
            case "staff": return <div className="card__btns">
                <Button addClass="button-small button-small--grey">Staff Expansion</Button>
                <Button addClass="button-small button-small--grey">i</Button>
            </div>
            default: return null
        }
    }

    const renderContent = () => {
        if (activeProfit && passiveProfit) {
            return <>
                <div className="card__profit">
                    <div className="card__label">Active Profit</div>
                    <div className="card__price">{activeProfit} BNB</div>
                </div>
                <div className="card__profit">
                    <div className="card__label">Passive Profit</div>
                    <div className="card__price">{passiveProfit} BNB</div>
                </div>
            </>
        }
        if (openDate) {
            return <div className="card__profit">
                <div className="card__label">opening soon</div>
                <div><Timer targetDate={openDate} /></div>
            </div>
        }
        if (price) {
            return <div className="card__price">{price} BNB</div>
        }
    }

    const renderStatuses = () => {
        return statuses.map(({type, text}, i) => (
            <div key={i} className={`card__status ${type}`}>{text}</div>
        ))
    }

    return (
        <div className={`card ${additionalClass} ${type === "unknown" ? "unknown" : ""}`}>
            {type !== 'unknown' ? <>
                <div className="card__statuses">
                    {renderStatuses()}
                </div>
                <div className="card__image">
                    <img src={image} alt="card" />
                </div>
                <div className="card__title">
                    {property}
                </div>
                <div className="card__content">
                    {renderContent()}
                </div>
                <div className="card__footer">
                    {renderButtons()}
                </div>
            </> : null}

        </div>
    )
}