export default function BCoinIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_16_1678)">
                <path d="M6.11584 8.4042L9.99984 4.5203L13.8858 8.4062L16.1458 6.1462L9.99984 0L3.85594 6.1442L6.11584 8.4042ZM0 9.99969L2.26006 7.73962L4.51999 9.99955L2.25992 12.2596L0 9.99969ZM6.11584 11.5958L9.99984 15.4797L13.8857 11.5939L16.1469 13.8527L16.1458 13.8539L9.99984 20L3.85574 13.856L3.85254 13.8528L6.11584 11.5958ZM15.4801 10.0009L17.7401 7.74087L20.0001 10.0008L17.74 12.2609L15.4801 10.0009Z" fill="#F3BA2F" />
                <path d="M12.292 9.99875H12.293L9.99972 7.70544L8.30492 9.40025H8.30482L8.11012 9.59505L7.70852 9.99675L7.70532 9.99985L7.70852 10.0031L9.99972 12.2944L12.293 10.0011L12.2941 9.99985L12.292 9.99875Z" fill="#F3BA2F" />
            </g>
            <defs>
                <clipPath id="clip0_16_1678">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}