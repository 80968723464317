import Layout from "./components/layout/layout";
import Main from "./pages/main";
import Account from './pages/account';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {

  if (typeof window.ethereum !== 'undefined') {
    console.log('MetaMask is installed!');
  }

  return (
    <div className="App" style={{height: '100%'}}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="account" element={<Account />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>

  );
}

export default App;
