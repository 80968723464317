import Action from "./action";
import {actionsArray} from './../../lib/actionsLib';
import Button from "../buttons/button";

export default function ActionsSection() {

    return (
        <div className="actions">
            <div className="actions__header">
                <div className="col1">Action</div>
                <div className="col2">Comment</div>
                <div>Amount</div>
            </div>
            <div className="actions__wrapper">
                {actionsArray.map(({image, action, comment, amount}, i) => (
                    <Action
                        key={i}
                        image={image}
                        action={action}
                        comment={comment}
                        amount={amount}
                    />
                ))}
            </div>
            <div className="actions__btn">
                <Button addClass="button-small shadow">See more</Button>
            </div>
        </div>
    )
}