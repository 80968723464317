import userAvatar from "../assets/images/avatar.png"
import BCoinIcon from "../assets/svg/bCoin"
import BusdCoinIcon from "../assets/svg/busdCoin"
import useMetaMask from './../utils/hooks/useMetaMask';

export default function UserCard({ }) {

    const {account} = useMetaMask();

    return (
        <div className="user-card">
            <div className="user-card__avatar">
                <img src={userAvatar} />
            </div>
            <div className="user-card__info">
                <div className="user-card__id">User ID: 00056784</div>
                <div className="user-card__address">{renderAddress(account || "")}</div>
                <div className="user-card__wallets">
                    <Wallet icon={<BCoinIcon />} amount={"13.328476 BNB"} />
                    <Wallet icon={<BusdCoinIcon />} amount={"5182.04 BUSD"} />
                </div>
            </div>
        </div>
    )
}

const renderAddress = (address) => {
    const first = address.substr(0, 4);
    const second = address.substr(-4);
    return first + "..." + second
}

const Wallet = ({icon, amount}) => {
    return (
        <div className="user-card__wallet">
            {icon}
            {amount}
        </div>
    )
}