import {questionsArray} from './../../lib/questionsLIb';
import AnswerItem from './answerItem';

export default function AnswerSection() {

    return (
        <div className="answer-section container">
            {questionsArray.map(({question, answer}, i) => (
                <AnswerItem
                    key={i}
                    question={question}
                    answer={answer}
                />
            ))}
        </div>
    )
}