import hotelImage from '../assets/images/property/hotel.png';
import factoryImage from '../assets/images/property/factory.png';
import busStation from '../assets/images/property/bus-stop.png';
import airportImage from '../assets/images/property/airport.png';
import oilrigImage from '../assets/images/property/oil-rig.png';

let threeDaysAfterNow = new Date();
threeDaysAfterNow.setDate(threeDaysAfterNow.getDate() + 3);

export const propertyTypes = [{
    'property': "Hotel",
    'type': 'upgrade',
    'image': hotelImage,
    'price': 0.15462,
}, {
    'property': "Bus station",
    'type': 'buy',
    'image': busStation,
    'price': 0.35462,
},
{
    'property': "Factory",
    'type': 'timer',
    'image': factoryImage,
    'price': 0.35462,
    'openDate': threeDaysAfterNow,
}, {
    'type': 'unknown'
}, {
    'type': 'unknown'
}, {
    'type': 'unknown'
}, {
    'type': 'unknown'
}, {
    'type': 'unknown'
}]

export const propertyTypesForAccount = [{
    'property': "Hotel",
    'type': 'staff',
    'image': hotelImage,
    'price': 0.15462,
    'activeProfit': 0.000334,
    'passiveProfit': 0.000076,
    'statuses': [{type: 'success', text: 'Active'}, {type: 'warning', text: 'Staff Expansion X5'}]
}, {
    'property': "Bus station",
    'type': 'staff',
    'image': busStation,
    'price': 0.35462,
    'activeProfit': 0.000334,
    'passiveProfit': 0.000076,
    'statuses': [{type: 'error', text: 'Crisis'}]
},
{
    'property': "Factory",
    'type': 'buy',
    'image': factoryImage,
    'price': 0.35462,
}, {
    'property': "Airport",
    'type': 'buy',
    'image': airportImage,
    'price': 0.35462,
},
{
    'property': "Factory",
    'type': 'timer',
    'image': factoryImage,
    'price': 0.35462,
    'openDate': threeDaysAfterNow,
}, {
    'type': 'unknown'
}]