import UserCard from '../components/userCard';
import BannerText from './../components/bannerText';
import useMetaMask from './../utils/hooks/useMetaMask';
import useRedirect from './../utils/hooks/useRedirect';
import LinkIcon from './../assets/svg/link';
import InfoSection from '../components/info-section/infoSection';
import ActionsSection from './../components/actionsSection/actionsSection';
import AnswerSection from './../components/answers-section/answersSection';
import {propertyTypesForAccount} from './../lib/propertyTypes';
import PropertyCard from '../components/properyCard';
import {SwiperSlide, Swiper} from 'swiper/react';
import InstructionSection from '../components/instructionSection/instructionSection';
import instruction1 from '../assets/images/instruction_1.png';
import instruction2 from '../assets/images/instruction_2.png';
import instructionMob1 from '../assets/images/instruction_mob_1.png';
import instructionMob2 from '../assets/images/instruction_mob_2.png';

import bg1 from '../assets/images/account-bg/account_bg_sq_1.png';
import bg2 from '../assets/images/main-bg/main_bg_sq_3.png';
import bg4 from '../assets/images/main-bg/main_bg_sq_4.png';
import bg8 from '../assets/images/main-bg/main_bg_dec_4.png';
import NotificationIcon from './../assets/svg/notification';



export default function Account() {

    const {isActive} = useMetaMask();
    useRedirect(!isActive, '/')

    return (
        <div className="account">

            <section className="account__banner banner container">
                <img src={bg1} />
                <div className='banner__left'>
                    <BannerText />
                </div>

                <div className='banner__right'>
                    <div className='banner__user'>
                        <UserCard />
                    </div>

                    <div className='banner__link'>
                        <LinkIcon />
                        Share the link for invite friends
                    </div>
                </div>
            </section>

            <section className='account__cards  container'>
                <div className='account-cards'>
                    <h2 className='account-cards__title'>Play Monopoly</h2>
                    <div className='account-cards__text'>Monopoly is a multi-player economics-themed board game. In the game, players roll two dice to move around the game board, buying and trading properties, and developing them with houses and hotels. Players collect rent from their opponents, with the goal being to drive them into bankruptcy. Money can also be gained or lost through Chance and Community Chest cards, and tax squares.</div>
                    <div className='account-cards__cards'>
                        {propertyTypesForAccount.map((item, i) => (
                            <PropertyCard
                                key={i}
                                property={item.property}
                                type={item.type}
                                image={item.image}
                                price={item.price}
                                activeProfit={item.activeProfit}
                                openDate={item?.openDate}
                                passiveProfit={item.passiveProfit}
                                statuses={item?.statuses}
                            />
                        ))}
                    </div>
                    <div className='account-cards__slider'>
                        <Swiper spaceBetween={20}
                            slidesPerView={'auto'}
                        >
                            {
                                propertyTypesForAccount.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <PropertyCard
                                            key={i}
                                            property={item.property}
                                            type={item.type}
                                            image={item.image}
                                            price={item.price}
                                            activeProfit={item.activeProfit}
                                            openDate={item?.openDate}
                                            passiveProfit={item.passiveProfit}
                                            statuses={item?.statuses}
                                        />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>

            </section>

            <section className='account__info'>
                <InfoSection />
                <img src={bg2} />
            </section>

            <section className='account__instruction container'>
                <InstructionSection
                    title={"Bonus distributions for the purchase of a new object"}
                    image={instruction1}
                    mobImage={instructionMob1}
                    text={<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
                        1.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
                        2.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
                        3.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
                        4.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>}
                />
            </section>

            <section className='account__instruction container'>
                <InstructionSection
                    title={"Bonus distributions for the purchase of a new object"}
                    image={instruction2}
                    mobImage={instructionMob2}
                    text={<div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
                        1.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
                        2.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
                        3.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
                        4.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>}
                />
            </section>

            <section className='account__numbers account-numbers container'>
                <div className='account-numbers__address'>
                    <div>
                        <NotificationIcon />
                        Smart contract address:
                    </div>

                    <div >0xd2188abdB7A437c49c9BC83640F824805d938482</div>

                </div>
                <div className='account-numbers__flex'>
                    <div className='account-numbers__card'>
                        <div className='account-numbers__title'>1 023 325
                            <div className='account-numbers__subtitle'>Objects have been purchased or upgraded</div>
                        </div>
                    </div>
                    <div className='account-numbers__card'>
                        <div className='account-numbers__title'>115 213
                            <div className='account-numbers__subtitle'>Unique user</div>
                        </div>
                    </div>
                    <div className='account-numbers__card'>
                        <div className='account-numbers__title'>0.000141 BNB
                            <div className='account-numbers__subtitle'>Average income per day</div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="account__actions">
                <img src={bg4} />
                <div className='container'>
                    <ActionsSection />
                </div>

            </section>

            <section className="account__answers">
                <img src={bg8} />
                <AnswerSection />
            </section>
        </div>
    )
}