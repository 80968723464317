export const questionsArray = [{
    question: "How it works?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu volutpat odio facilisis mauris sit amet massa. Fusce id velit ut tortor. Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Justo eget magna fermentum iaculis eu non diam phasellus. Ultricies integer quis auctor elit sed. Ut faucibus pulvinar elementum integer enim neque. Eu mi bibendum neque egestas congue quisque egestas diam in. Nisi vitae suscipit tellus mauris a diam maecenas sed. Tellus cras adipiscing enim eu turpis egestas pretium aenean. Ut tristique et egestas quis. Mauris pharetra et ultrices neque ornare aenean euismod."
}, {
    question: "How to connect my wallet?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu volutpat odio facilisis mauris sit amet massa. Fusce id velit ut tortor. Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Justo eget magna fermentum iaculis eu non diam phasellus. Ultricies integer quis auctor elit sed. Ut faucibus pulvinar elementum integer enim neque. Eu mi bibendum neque egestas congue quisque egestas diam in. Nisi vitae suscipit tellus mauris a diam maecenas sed. Tellus cras adipiscing enim eu turpis egestas pretium aenean. Ut tristique et egestas quis. Mauris pharetra et ultrices neque ornare aenean euismod."
},
{
    question: "How to buy/upgrade objects?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu volutpat odio facilisis mauris sit amet massa. Fusce id velit ut tortor. Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Justo eget magna fermentum iaculis eu non diam phasellus. Ultricies integer quis auctor elit sed. Ut faucibus pulvinar elementum integer enim neque. Eu mi bibendum neque egestas congue quisque egestas diam in. Nisi vitae suscipit tellus mauris a diam maecenas sed. Tellus cras adipiscing enim eu turpis egestas pretium aenean. Ut tristique et egestas quis. Mauris pharetra et ultrices neque ornare aenean euismod."
},
{
    question: "How to recover my password?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu volutpat odio facilisis mauris sit amet massa. Fusce id velit ut tortor. Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Justo eget magna fermentum iaculis eu non diam phasellus. Ultricies integer quis auctor elit sed. Ut faucibus pulvinar elementum integer enim neque. Eu mi bibendum neque egestas congue quisque egestas diam in. Nisi vitae suscipit tellus mauris a diam maecenas sed. Tellus cras adipiscing enim eu turpis egestas pretium aenean. Ut tristique et egestas quis. Mauris pharetra et ultrices neque ornare aenean euismod."
},
{
    question: "I need to get in touch with your customer service! How do I get to them? ",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu volutpat odio facilisis mauris sit amet massa. Fusce id velit ut tortor. Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Justo eget magna fermentum iaculis eu non diam phasellus. Ultricies integer quis auctor elit sed. Ut faucibus pulvinar elementum integer enim neque. Eu mi bibendum neque egestas congue quisque egestas diam in. Nisi vitae suscipit tellus mauris a diam maecenas sed. Tellus cras adipiscing enim eu turpis egestas pretium aenean. Ut tristique et egestas quis. Mauris pharetra et ultrices neque ornare aenean euismod."
},
{
    question: "How much can I earn?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu volutpat odio facilisis mauris sit amet massa. Fusce id velit ut tortor. Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Justo eget magna fermentum iaculis eu non diam phasellus. Ultricies integer quis auctor elit sed. Ut faucibus pulvinar elementum integer enim neque. Eu mi bibendum neque egestas congue quisque egestas diam in. Nisi vitae suscipit tellus mauris a diam maecenas sed. Tellus cras adipiscing enim eu turpis egestas pretium aenean. Ut tristique et egestas quis. Mauris pharetra et ultrices neque ornare aenean euismod."
}]