import {useState} from 'react';
import PlusIcon from '../../assets/svg/plus';

export default function AnswerItem({question, answer}) {

    const [open, setOpen] = useState(false);

    return (
        <div className={`answer ${open ? "open" : ""}`}>
            <div className='answer__header' onClick={() => setOpen(prev => !prev)}>
                <div className='answer__title'>{question}</div>
                <PlusIcon />
            </div>
            <div className='answer__content'>
                {answer}
            </div>
        </div>
    )

}