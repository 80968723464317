import CashCoinIcon from "../assets/svg/cashCoin";
import UpgradeIcon from './../assets/svg/upgrade';
import CartIcon from './../assets/svg/cart';

export const actionsArray = [{
    image: <CashCoinIcon />,
    action: "Calculation of profit",
    comment: "Calculation of Hotel level 2 profit",
    amount: 0.000141,
},
{
    image: <CashCoinIcon />,
    action: "Calculation of profit",
    comment: "Calculation of Hotel level 2 profit",
    amount: 0.000141,
}, {
    image: <UpgradeIcon />,
    action: "Hotel level 2",
    comment: "Upgrade of the object",
    amount: -0.000141,
}, {
    image: <CashCoinIcon />,
    action: "Calculation of profit",
    comment: "Calculation of Hotel level 2 profit",
    amount: 0.000141,
},
{
    image: <CashCoinIcon />,
    action: "Calculation of profit",
    comment: "Calculation of Hotel level 2 profit",
    amount: 0.000141,
},
{
    image: <CashCoinIcon />,
    action: "Calculation of profit",
    comment: "Calculation of Hotel level 2 profit",
    amount: 0.000141,
},
{
    image: <CashCoinIcon />,
    action: "Calculation of profit",
    comment: "Calculation of Hotel level 2 profit",
    amount: 0.000141,
},
{
    image: <CashCoinIcon />,
    action: "Calculation of profit",
    comment: "Calculation of Hotel level 2 profit",
    amount: 0.000141,
},
{
    image: <CartIcon />,
    action: "Hotel level 2",
    comment: "Purchase of a new object",
    amount: -0.000141,
}]